import axios from 'axios';

if(document.getElementById("regionselector")){

    new Vue({
        el: '#regionselector',
        data () {
            return {
                regions:'',
                dropDownOpen: false,
                selectedRegion: '',
            }
        },
        computed: {
            selectedRegionDropdownText: function() {
                if(this.selectedRegion.length){
                    return this.selectedRegion 
                }else {
                    return 'Vælg region';
                }
            },
        },
        methods: {
            closeDropdown: function() {
                this.dropDownOpen = false
            },
            
            clickOutside(e) {
                if (! this.$el.contains(e.target)) {
                    this.dropDownOpen = false
                }
            }
        },
        created() {
            window.addEventListener('click', this.clickOutside)
        },        
        beforeDestroy() {
            window.removeEventListener('click', this.clickOutside)
        },
        mounted () {
            axios
            .get('/api/jobs/regions')
            .then(response => (this.regions = response.data.items))
        },    

    })
}
