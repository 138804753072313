const menuToggler = document.querySelector('[data-mobile-menu-toggler]')
const mobileMenu = document.querySelector('[data-mobile-menu]')
const body = document.querySelector('body')
const menuLinks = document.querySelectorAll('[data-mobile-link]')
const mobileMenuClose = document.querySelector('.mobile-menu-close')
const mobileMenuOpen = document.querySelector('.mobile-menu')

let menuOpen = false

menuToggler.addEventListener('click', e => {
  body.classList.toggle('overflow-hidden')
  body.classList.toggle('touch-none') // This is needed to disable scrolling on IOS safari
  mobileMenu.classList.toggle('opacity-0')
  mobileMenu.classList.remove('invisible')
  menuToggler.toggleAttribute('aria-expanded')
  mobileMenu.toggleAttribute('aria-hidden')

  menuLinks.forEach(link => {
    link.classList.toggle('opacity-0')
    link.classList.toggle('translate-y-2')
  })
 
  if (!menuOpen) {
    mobileMenuClose.classList.remove('hidden')
    mobileMenuOpen.classList.add('hidden')
  } else {
    mobileMenuClose.classList.add('hidden')
    mobileMenuOpen.classList.remove('hidden')
  }


  menuOpen = !menuOpen
})


menuLinks.forEach(link => {
  const button = link.querySelector('button')
  const subMenu = link.querySelector('ul')

  if(subMenu === null) return

  button.addEventListener('click', e => {
    button.toggleAttribute('aria-expanded')
    subMenu.toggleAttribute('aria-hidden')

    subMenu.classList.toggle('hidden')
  })

})