const accordions = document.querySelectorAll('[data-accordion]')
const isAnimatingClassNames = ['h-0', 'opacity-0']

function setHeightAndOpacity(panel) {
  panel.style.height = panel.scrollHeight + 'px'
  panel.style.opacity = '1'
}

accordions.forEach(accordion => {
  const toggler = accordion.querySelector('[data-accordion-toggler]')
  const arrow = toggler.querySelector('svg')
  const panel = toggler.nextElementSibling
  let isTransitioning = false
  let panelOpen = false

  toggler.addEventListener('click', () => {
    if (isTransitioning) return

    if (!panelOpen) {
      toggler.classList.add('text-red-500')
      arrow.classList.add('rotate-180')
      panel.classList.remove('hidden')
      panel.classList.add(...isAnimatingClassNames)

      setHeightAndOpacity(panel)
    } else {
      arrow.classList.remove('rotate-180')
      toggler.classList.remove('text-red-500')
      setHeightAndOpacity(panel)

      setTimeout(e => {
        panel.style.height = '0px'
        panel.style.opacity = '0'
      }, 100)
    }

    isTransitioning = true
  })

  panel.addEventListener('transitionend', e => {
    if (e.propertyName !== 'height') return

    isTransitioning = false
    panelOpen = !panelOpen
    toggler.toggleAttribute('aria-expanded')
    panel.toggleAttribute('aria-hidden')
    panel.style = null

    panelOpen ? panel.classList.remove(...isAnimatingClassNames) : panel.classList.add('hidden')
  })
})
