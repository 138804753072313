import axios from 'axios';
moment.locale('da');

if(document.getElementById("related-jobs")) {
  new Vue({
    el: '#related-jobs',
    data () {
      return {
        jobs: []
      }
    },
    methods: {
      getItems() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("id");
        axios.get(`/api/jobs/${id}/similarJobs`).then(response => {
          this.jobs = response.data.data;
        }); 
      },
      formatDate: function (date) {
        return moment(date, 'YYYY-MM-DD').format('D MMM YYYY');
      },
    },
    mounted() {
      this.getItems();
    },
  })
}