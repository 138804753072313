import '@splidejs/splide/dist/css/splide-core.min.css'
import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('.splide')
  sliders.forEach(slider => { initializeSlider(slider) })
})

function initializeSlider(slider) {
  var splide = new Splide(slider, {
    type: 'fade',
    rewind: true,
    autoplay: true,
    pauseOnHover: true
  });
  splide.mount();
}