import axios from 'axios';
moment.locale('da');

if(document.getElementById("joblist")){
  new Vue({
      el: '#joblist',
      data () {
        return {
          jobsApi: [],
          regions: '',
          categories: [], 
          selectedCategories: [],
          selectedRegions: [],
          countOfPage: 10,
          currPage: 1,
          sorting: 'DSC',
          updatingSkeleton: false,
          filtersToggled: true,
          regionFromUrl: '',
          regionsDropDownOpen: false,
          categoriesDropDownOpen: false,
          appLoading: false
        }
      },
      computed: {
        jobs: function(){
          return Object.values(
            this.jobsApi.sort((a, b) => a.postDate - b.postDate ).reverse()
          )
        },

        sortedJobs: function(){
          if(this.sorting === 'DSC' || this.sorting === 'ASC' ){
            return this.jobs.reverse();
          }
        },

        filteredJobs: function () {

          function updateCategoryCount(category, regions) {
            const mappedRegions = category.regions.filter(function (categoryRegion) {
              return regions.length === 0 || regions.some(function (region) {
                return region === categoryRegion.name;
              });
            });

            category.count = mappedRegions?.length > 0 ? mappedRegions.map(item => item.count).reduce((prev, next) => prev + next) : 0;
          }

          let filteredJobs = [];
          let categories = this.selectedCategories;
          let regions = this.selectedRegions;

          this.categories.forEach(function (cat) {
            updateCategoryCount(cat, regions);
          });

          if(!this.selectedCategories.length && !this.selectedRegions.length ) {
            return this.sortedJobs;
          }

          this.sortedJobs.forEach(function(job) {
            
            function jobContainsCategories(filter) {
              return job.categories.indexOf(filter) != -1;
            }
            
            function jobContainsRegions(filter) {
              return job.regions.indexOf(filter) != -1;
            }
            
            if(regions.length){
              if(!categories.length){
                if( regions.some(jobContainsRegions)) {
                  filteredJobs.push(job);
                }
              }
              else{
                if(categories.some(jobContainsCategories) && regions.some(jobContainsRegions)) {
                  filteredJobs.push(job);
                }
              }
            } 
            else{
              if(categories.some(jobContainsCategories) || regions.some(jobContainsRegions)) {
                filteredJobs.push(job);
              }
            }
            
          });
        
          return filteredJobs;
        },
        
        pageStart: function(){
          return (this.currPage - 1) * this.countOfPage;
        },
        totalPage: function(){
          return Math.ceil(this.filteredJobs.length / this.countOfPage);
        },
      
        jobsShownString: function(){
          if(this.filteredJobs.length == 1){
            return 'Viser ' + this.filteredJobs.length + ' stilling'
          }
          else {
            return 'Viser ' + this.filteredJobs.length + ' stillinger'
          }
        },

      },
      methods: {
    
        filterProductsByCategory: function(products){
          return products.filter(function (item) {
            return this.selectedCategories.some(elem => item.categories.includes(elem));
          }, this)
        },

        filterProductsByRegion: function(products) {
          return products.filter(function (item) {
            return this.selectedRegions.some(elem => item.regions.includes(elem));
          }, this)
        },
        

        setPage: function(idx){
          if( idx <= 0 || idx > this.totalPage ){
            return;
          }
          this.currPage = idx;

          document.getElementById("joblist").scrollIntoView({ behavior: "smooth" });
        },
        notNearbyPage: function(n){
          // if(n == 1 || n == this.totalPage){ 
          //   return false
          // }
          // else 
          let front = Math.max(6 - this.currPage, 3);
          let back = this.currPage + front > this.totalPage ?
            5 - (this.totalPage - this.currPage) : 
            3;
          
          if(n < this.currPage - back || n > this.currPage + front ){
            return true
          }
        },

        formatDate : function (date) {
            return moment(date, 'YYYY-MM-DD').format('LL');
        },

        domUpdateAnimation() {
          this.updatingSkeleton = true;
          setTimeout(() => {
            this.updatingSkeleton = false;
          }, 1000);  
        },

        toggleFilters: function() {
          this.filtersToggled = !this.filtersToggled;
        },
        
        toggleRegionsDropDown: function() {
          this.regionsDropDownOpen = !this.regionsDropDownOpen;
        },
        
        toggleCategoriesDropDown: function() {
          this.categoriesDropDownOpen = !this.categoriesDropDownOpen;
        },

        onResize() {
          if (window.innerWidth > 1024) {
            this.filtersToggled = true // Always show filters on lg breakpoint
          }
        },

        init() {
          var self = this;
          this.appLoading = true;
          axios
            .all([
              axios.get('/api/jobs').then(response => (
                this.jobsApi = response.data.items
              )),

              axios.get('/api/jobs/regions').then(response => (
                this.regions = response.data.items
              )),

              axios.get('/api/jobs/categories').then(response => (
                this.categories = response.data.items
              ))
            ])
            .then(
              axios.spread(function (res) {
                self.appLoading = false;
              })
            )
            .catch(error => console.log(error));
        }
      },
      watch: {
        countOfPage: function() {
          this.domUpdateAnimation()
        },
        totalPage: function() {
          this.currPage = 1
        },
      },
      mounted () {

        this.init();

        // Preselect region filter based on URL paramater
        const urlParams = new URLSearchParams(window.location.search);
        // this.regionFromUrl = urlParams.get("region");
        if(urlParams.get("region")){
          this.selectedRegions.push(urlParams.get("region"))
        }
        
        // Trigger resize check when first Mounted
        this.onResize();
          
        // Smoothscroll - most meant for scroll to top on pagination changes
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
          anchor.addEventListener('click', function (e) {
              e.preventDefault();
      
              document.querySelector(this.getAttribute('href')).scrollIntoView({
                  behavior: 'smooth'
              });
          });
        });

      },
      created() {
        window.addEventListener('resize', this.onResize)
      },
      beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
      },

      
  })
}


// https://codepen.io/frontinorz/pen/wmNoaR - Pagination based on
// https://www.designcise.com/web/tutorial/how-to-check-if-an-array-contains-all-elements-of-another-array-in-javascript Filtering
