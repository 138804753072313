import './header'
import './mobile-menu'
import './aos'
import './testimonial-slider'
import './accordion'
import './joblist'
import './region-selector'
import './jobpage'
import './sick-form'
import './related-jobs'

const adeccoForm = document.getElementById('adeccoForm');
if (adeccoForm) {
  const submitButton = document.getElementById('adeccoFormSubmit');
  adeccoForm.addEventListener('submit', (event) => {
    submitButton.disabled = true;
  });
}
const sickForm = document.getElementById('sickForm');
if (sickForm) {
  const submitButton = document.getElementById('sickFormSubmit');
  sickForm.addEventListener('submit', (event) => {
    submitButton.disabled = true;
  });
}