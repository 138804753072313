import throttle from 'lodash.throttle'

document.addEventListener('DOMContentLoaded', () => {
  const mainContent = document.querySelector('#main-content')
  const body = document.querySelector('body')
  const header = document.querySelector('[data-site-header]')
  const logo = header.querySelector('[data-logo]')
  const menuList = header.querySelector('[data-menu-list]')
  const subMenuList = header.querySelector('[data-submenu-list]')

  let prevScrollpos = window.scrollY;

  function minimize(){
    logo.classList.add('lg:scale-100')
    logo.classList.remove('lg:scale-75')

    menuList.classList.add('style-lead', 'h-24')
    menuList.classList.remove('style-body', 'h-14')

    if(subMenuList !== null) {
      subMenuList.classList.add('style-lead-mobile', 'h-16')
      subMenuList.classList.remove('style-body', 'h-14')
    }
  }

  function maximize(){
    logo.classList.add('lg:scale-75')
    logo.classList.remove('lg:scale-100')

    menuList.classList.add('style-body', 'h-14')
    menuList.classList.remove('style-lead', 'h-24')

    if(subMenuList !== null) {
      subMenuList.classList.add('style-body', 'h-14')
      subMenuList.classList.remove('style-lead-mobile', 'h-16')
    }
  }


  function stickyHeader() {
    const headerHeight = header.clientHeight
    let currentScrollPos = window.scrollY

    if (currentScrollPos > headerHeight * 1.5) {
      if (prevScrollpos > currentScrollPos) {
        header.classList.add('fixed', 'translate-y-0')
        header.classList.remove('absolute', '-translate-y-full')
        
        body.classList.add('[--menu-offset:70px]') // Used for filters on joblist page, that is sticky to top of viewport
        body.classList.remove('[--menu-offset:20px]')
      }
      else {
        header.classList.add('-translate-y-full')
        header.classList.remove('translate-y-0')
        
        body.classList.add('[--menu-offset:20px]')
        body.classList.remove('[--menu-offset:70px]')
        
        maximize()
      }
    }
    else if (currentScrollPos === 0) {
      header.classList.add('absolute')
      header.classList.remove('fixed')
      minimize()
    }
    else {
      minimize()
    }

    prevScrollpos = currentScrollPos <= 0 ? 0 : currentScrollPos
  }

  window.addEventListener('scroll', stickyHeader)
  // window.addEventListener('scroll', throttle(stickyHeader, 70)) // Throttle is making trouble detection when scroll hits top of page... 
})
