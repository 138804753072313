if(document.getElementById("jobPage")){
    new Vue({
      el: '#jobPage',
      methods: {
        goToApplyForm: function () {
          const el = document.querySelector("#applyForm");
          if (el) {
            el.style.removeProperty('display');
            el.scrollIntoView({
              behavior: 'smooth'
            });
            dataLayer.push({
              event: 'actions',
              eventCategory: 'job actions',
              eventAction: 'click',
              eventLabel: 'apply',
            });
          }
        }
      },
      mounted () {
        // Smoothscroll - most meant for scroll to top on pagination changes
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
          anchor.addEventListener('click', function (e) {
            e.preventDefault();
          });
        });
      },			
    })
}